import { create } from 'zustand';

import {
    FORM_COMPLETE_STEP,
    FORM_CURRENT_STEP,
    FORM_UPCOMING_STEP,
} from '@src/constants/form401k';

const bankStepState = create((set) => ({
    clear: () => set(bankStepState.getInitialState()),
    form_ready: false,
    setFormReady: (ready) => set(() => ({ form_ready: ready })),
    currentStep: 'chooseAccount',
    setCurrentStep: (step) => set(() => ({ currentStep: step })),
    steps: [
        {
            value: 'chooseAccount',
            name: 'Choose an Account',
            short: 'account',
        },
        {
            value: 'fillApplication',
            name: 'Fill Out Bank Application',
            short: 'application',
        },
        {
            value: 'identityDetails',
            name: 'Identity Verification',
            short: 'identity',
        },
        {
            value: 'inProgress',
            name: 'In Progress',
            short: 'inProgress',
        },
        {
            value: 'stepUpNeeded',
            name: 'More Documentation Needed',
            short: 'stepUp',
        },
        {
            value: 'ConnectBank',
            name: 'Connect Your Primary Bank Account',
            short: 'connectBank',
        },

    ],
    setStepUp: () => set((state) => {
        const { currentStep, steps } = state;
        const newSteps = [...steps];
        const stepIdx = newSteps.findIndex((s) => s.value === currentStep);
        newSteps[stepIdx].status = FORM_COMPLETE_STEP;
        newSteps[stepIdx + 1].status = FORM_CURRENT_STEP;
        return {
            steps: newSteps,
            currentStep: newSteps[stepIdx + 1].value,
        };
    }),
    setStepDown: () => set((state) => {
        const { currentStep, steps } = state;
        const newSteps = [...steps];
        const stepIdx = newSteps.findIndex((s) => s.value === currentStep);
        newSteps[stepIdx].status = FORM_UPCOMING_STEP;
        newSteps[stepIdx - 1].status = FORM_CURRENT_STEP;
        return {
            steps: newSteps,
            currentStep: newSteps[stepIdx - 1].value,
        };
    }),
    personalDetails: {
        title: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        DOB: '',
        address01: '',
        address02: '',
        city: '',
        state: '',
        state_long: '',
        postal_code: '',
        email: '',
        phone: '',
        mailing_address01: '',
        mailing_address02: '',
        mailing_city: '',
        mailing_state: '',
        mailing_state_long: '',
        mailing_postal_code: '',
        ssn: '',
        occupation: '',
        married: false,
        spouse_first_name: '',
        spouse_last_name: '',
        spouse_email: '',
        control_group: false,
        step_personal_details: false,
    },
    identityDetails: {
        id_type: '',
        id_photo_number: '',
        id_state: '',
        id_expiry_date: '',
        id_photo_picture: '',
        id_photo_picture2: '',
        step_identity_details: false,
    },
    planDetails: {
        plan_loan: true,
        plan_roth: true,
        plan_roth_roll: true,
        plan_name: '',
        plan_ein: '',
        plan_establish_date: null,
        loan_interest_rate: '',
        loan_interest_rate_type: '',
        step_plan_details: false,
    },
    employerDetails: {
        employer_name: '',
        employer_address01: '',
        employer_address02: '',
        employer_city: '',
        employer_state: '',
        employer_state_long: '',
        employer_postal_code: '',
        employer_mailing_address01: '',
        employer_mailing_address02: '',
        employer_mailing_city: '',
        employer_mailing_postal_code: '',
        employer_mailing_state: '',
        employer_mailing_state_long: '',
        employer_phone: '',
        employer_email: '',
        employer_website: '',
        employer_ein: '',
        entity_type: '',
        employer_incorp_date: '',
        employer_incorporation_state: '',
        employer_fiscal_year_end: '',
        employer_fiscal_year_end_date: '',
        step_employer_details: false,
    },
    bankDetails: {
        bankPin: '',
        bank_account_monthly_activity_domestic: '',
        bank_account_monthly_activity_international: '',
        bank_assets_type: '',
        bank_income: '',
        bank_source_of_funds: '',
        bank_terms_agree: false,
        plan_signature: '',
        plan_signer_first_name: '',
        plan_signer_last_name: '',
        plan_signer_title: '',
        plan_sign_date: '',
        step_bank_details: false,
        isSelectedSignature: false,
    },
    partnerDisplayName: '',
    setPartnerDisplayName: (data) => set(() => ({ partnerDisplayName: data })),
    setPersonalDetails: (data) => set(() => ({ personalDetails: data })),
    setPlanDetails: (data) => set(() => ({ planDetails: data })),
    setEmployerDetails: (data) => set(() => ({ employerDetails: data })),
    setBankDetails: (data) => set(() => ({ bankDetails: data })),
    setIdentityDetails: (data) => set(() => ({ identityDetails: data })),
    bankJson: null,
    setBankJson: (data) => set(() => ({ bankJson: data })),
    configureParticipantSteps: () => set(({ steps }) => {
        const stepsToSkip = ['planDetails', 'employerDetails'];
        const participantSteps = steps.filter(
            ({ value }) => !stepsToSkip.includes(value),
        );

        return {
            steps: participantSteps,
        };
    }),
}));

export default bankStepState;
